import { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'debounce';
import styles from './CoursesPage.module.css';
import { Courses } from '../../organisms';
import { useFetchCourses } from '../../utils/hooks';
import { selectUser } from '../../redux/Login/LoginSlice';
import { LanguageSelector } from '../../molecules';
import { logoutUser } from '../../redux/Login/LoginSlice/actionCreators';
import { AppDispatch } from '../../store';
import { logoutRequest } from '../../utils/api/legacy-api/auth-api/auth';
import { setLoading } from '../../redux/LoadingSlice';
import { UserCard } from '../../molecules/UserCard';
import {
  CourseStatus,
  UserCourseResult,
  CourseType,
} from '../../utils/interfaces';
import { SEARCH_DEBOUNCE_DURATION_MS } from '../../config/consts';
import { HamburgerMenuButton } from '../../molecules/HamburgerMenuButton';
import { CoursesPageModal } from '../../organisms/CoursesPageModal';

export const CoursesPage: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [searchParams] = useSearchParams();

  const getSelectedItemFromParam = () => {
    const selectedCourseStatusParam =
      CourseStatus[
        searchParams.get('course_status') as keyof typeof CourseStatus
      ];
    const selectedCourseTypeParam =
      CourseType[searchParams.get('type') as keyof typeof CourseType];

    if (selectedCourseStatusParam) {
      return selectedCourseStatusParam;
    } else if (selectedCourseTypeParam) {
      return selectedCourseTypeParam;
    }
    return CourseStatus[CourseStatus.Ongoing];
  };

  const selectedItem = getSelectedItemFromParam();
  const page = searchParams.get('page') ?? '1';
  const searchText = searchParams.get('search') ?? '';
  const user = useSelector(selectUser);
  const {
    courses,
    courseStatusCounts,
    highlightedCourse,
    coursePremiumCount,
    paginationMeta,
    error,
    setCourseStatus,
    setPage,
    setSearch,
    setCourseType,
  } = useFetchCourses(
    CourseStatus[selectedItem as keyof typeof CourseStatus],
    page,
    searchText,
    CourseType[selectedItem as keyof typeof CourseType],
  );
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const doLogout = async () => {
    dispatch(setLoading(true));
    await logoutRequest();
    dispatch(logoutUser());
    dispatch(setLoading(false));
    window.location.reload();
  };

  const debouncedUpdateSearch = debounce((nextSearchText: string) => {
    setSearch(nextSearchText);
  }, SEARCH_DEBOUNCE_DURATION_MS);

  const onChangeCourseStatusClick = (nextCourseStatus: CourseStatus) => {
    setCourseStatus(nextCourseStatus);
  };

  const onCourseCardClick = (course: UserCourseResult) => {
    const currentUrlSearch = location.search;
    navigate(`/courses/${course._id}`, {
      state: { from: `/${currentUrlSearch}` },
    });
  };

  const onChangeCourseTypeClick = (nextCourseType: CourseType) => {
    setCourseType(nextCourseType);
  };

  const onChangePageClick = (nextPage: number) => {
    setPage(nextPage);
  };

  const onChangeSearch = (nextSearchText: string) => {
    debouncedUpdateSearch(nextSearchText);
  };

  if (error) {
    if (error?.response?.status === 401) {
      dispatch(logoutUser());
      dispatch(setLoading(false));
      window.location.reload();
      return;
    }
  }

  return (
    <div data-testid="courses-page" className={styles.container}>
      <div className={styles.userAndLanguageContainer}>
        <div className={styles.languageSelector}>
          <LanguageSelector hasBorder={false} />
        </div>
        <div className={styles.userCard}>
          <UserCard
            hasBorder={false}
            doLogout={doLogout}
            name={user?.displayName ?? 'Learner'}
          />
        </div>
        <HamburgerMenuButton
          isOpen={isMenuOpen}
          onClick={() => {
            setIsMenuOpen((prev) => !prev);
          }}
        />
      </div>
      <CoursesPageModal
        isMenuOpen={isMenuOpen}
        logoutRequest={logoutRequest}
        logoutUser={() => dispatch(logoutUser())}
      />
      <Courses
        name={user?.displayName ?? 'Learner'}
        selectedMenuItem={selectedItem}
        courses={courses}
        coursePremiumCount={coursePremiumCount}
        courseStatusCounts={courseStatusCounts}
        highlightedCourse={highlightedCourse}
        paginationData={paginationMeta}
        onChangeCourseStatusClick={onChangeCourseStatusClick}
        onCourseCardClick={onCourseCardClick}
        onChangeCourseTypeClick={onChangeCourseTypeClick}
        onChangePageClick={onChangePageClick}
        onChangeSearch={onChangeSearch}
      />
    </div>
  );
};
