/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { CourseStatus, CourseType, UserCourseResult } from '../interfaces';
import { getCourses } from '../api/academy-api/courses';
import { useSearchParams } from 'react-router-dom';
import { PAGINATION_LIMIT__COURSES } from '../../config/consts';
import { ICoursePaginationRequest } from '../interfaces/academy-api/icoursePaginationRequest';
import { CourseStatusCounts } from '../interfaces/academy-api/icoursePaginationResponse';
import { Constants } from '@mono/common';
import { pageToOffset } from '../frontendUtils';

type CoursesData = {
  /** @note 'undefined' until loaded, then array of 'UserCourseResult' of the user, with length >= 0 */
  courses: UserCourseResult[] | undefined;
  highlightedCourse?: UserCourseResult | null;
  courseStatusCounts: CourseStatusCounts;
  paginationMeta?: Awaited<ReturnType<typeof getCourses>>['meta'];
  coursePremiumCount: number;
};

const initialCoursesData: CoursesData = {
  courses: undefined,
  highlightedCourse: undefined,
  courseStatusCounts: {
    [CourseStatus.Completed]: 0,
    [CourseStatus.Ongoing]: 0,
    [CourseStatus.Todo]: 0,
  },
  paginationMeta: undefined,
  coursePremiumCount: 0,
};

export const useFetchCourses = (
  courseStatus?: CourseStatus,
  page: string | undefined = '1',
  search?: string,
  courseType?: CourseType,
) => {
  const setSearchParams = useSearchParams()[1];
  const [coursesData, setCoursesData] =
    useState<CoursesData>(initialCoursesData);
  const [requestParams, setRequestParams] = useState<ICoursePaginationRequest>({
    courseStatus,
    type: courseType,
    limit: PAGINATION_LIMIT__COURSES,
    offset: pageToOffset(PAGINATION_LIMIT__COURSES, page),
    search,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any | null>(null);

  useEffect(() => {
    const pageIndex = Number(page) - 1;

    if (pageIndex < 0) {
      setPage(1, true);
      setCourseStatus(CourseStatus.Ongoing);
      return;
    }

    const offset = pageToOffset(PAGINATION_LIMIT__COURSES, page);
    setRequestParams({
      courseStatus,
      type: courseType,
      limit: PAGINATION_LIMIT__COURSES,
      offset,
      search,
    });
  }, [courseStatus, page, search, courseType]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await getCourses(requestParams);
        setCoursesData({
          courses: response.data,
          highlightedCourse: response.highlightedCourse ?? null,
          courseStatusCounts: response.courseStatusCounts,
          paginationMeta: response.meta,
          coursePremiumCount: response.coursePremiumCount,
        });
      } catch (err: any) {
        if (
          err?.response?.data?.code !==
          Constants.ResponseCode.ACADEMY_ACCESS_DENIED
        ) {
          setError(err);
        }
        console.warn('Academy access denied'); // In case user doesn't have access to the academy, use only initialCoursesData
        setCoursesData({
          courses: [],
          highlightedCourse: null,
          courseStatusCounts: {
            [CourseStatus.Completed]: 0,
            [CourseStatus.Ongoing]: 0,
            [CourseStatus.Todo]: 0,
          },
          paginationMeta: undefined,
          coursePremiumCount: 0,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [requestParams.courseStatus, requestParams.offset, requestParams.search]);

  useEffect(() => {
    if (coursesData.paginationMeta) {
      const pageIndex = Number(page) - 1;
      const minPossiblePageIndex = 0;
      const maxPossiblePageIndex = Math.max(
        coursesData.paginationMeta.totalPages - 1,
        0,
      );
      if (
        pageIndex > maxPossiblePageIndex ||
        pageIndex < minPossiblePageIndex
      ) {
        setPage(1);
        return;
      }
    }
  }, [coursesData]);

  const patchSearchParams = ({
    courseStatus,
    type,
    page,
    search,
  }: {
    courseStatus?: CourseStatus;
    type?: CourseType;
    page?: number;
    search?: string;
  }) => {
    const _page = page || 1;
    const _courseStatus =
      courseStatus ?? requestParams.courseStatus ?? CourseStatus.Ongoing;
    const _courseType = type ?? requestParams.type;
    const _search = search ?? requestParams.search;
    if (_courseType && !courseStatus) {
      setSearchParams(
        `page=${_page}${_search ? `&search=${_search}` : ''}${_courseType ? `&type=${_courseType}` : ''}`,
      );
    } else {
      setSearchParams(
        `course_status=${_courseStatus}&page=${_page}${_search ? `&search=${_search}` : ''}`,
      );
    }
  };

  const setCourseStatus = (nextCourseStatus: CourseStatus | undefined) => {
    patchSearchParams({ courseStatus: nextCourseStatus });
  };

  const setCourseType = (nextCourseType: CourseType | undefined) => {
    patchSearchParams({ type: nextCourseType });
  };

  const setPage = (nextPage: number, _force?: boolean) => {
    if (!_force) {
      if (!coursesData.paginationMeta || typeof nextPage !== 'number') return;
      if (coursesData.paginationMeta.currentPage === nextPage) return;
    }

    patchSearchParams({ page: nextPage });
  };

  const setSearch = (search: string) => {
    patchSearchParams({ search });
  };

  return {
    courses: coursesData.courses,
    highlightedCourse: coursesData.highlightedCourse,
    courseStatusCounts: coursesData.courseStatusCounts,
    paginationMeta: coursesData.paginationMeta,
    coursePremiumCount: coursesData.coursePremiumCount,
    loading,
    error,
    requestParams,
    setCourseStatus,
    setCourseType,
    setPage,
    setSearch,
  };
};
